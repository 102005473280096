import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

// Add hasLoaded flag to track initial load
export const fetchHomeData = createAsyncThunk(
  'home/fetchHomeData', 
  async (_, { getState, rejectWithValue }) => {
    // Get current state
    const state = getState();
    
    // Check if data has already been loaded
    if (state.home.hasLoaded) {
      return null; // Return null if data is already loaded
    }

    try {
      const response = await axiosInstance.get('/home');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    sliders: [],
    categories: [],
    products: [],
    blogs: [],
    siteinfos: [],
    loading: false,
    error: null,
    hasLoaded: false, // Add hasLoaded flag to initial state
  },
  reducers: {
    // Optional: Add a reset action if you ever need to force a reload
    resetHomeData: (state) => {
      state.hasLoaded = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHomeData.fulfilled, (state, action) => {
        state.loading = false;
        // Only update state if we received new data
        if (action.payload) {
          state.sliders = action.payload.sliders;
          state.categories = action.payload.categories;
          state.products = action.payload.products;
          state.compaign = action.payload.compaign;
          state.feature = action.payload.featureProducts;
          state.blogs = action.payload.blogs;
          state.siteinfos = action.payload.siteinfos;
          state.hasLoaded = true; // Mark data as loaded after successful fetch
        }
      })
      .addCase(fetchHomeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export reset action if needed
export const { resetHomeData } = homeSlice.actions;

// Export the reducer as default
export default homeSlice.reducer;

// Add hasLoaded selector
export const selectHasLoaded = (state) => state.home.hasLoaded;

// Other selectors remain the same
export const getslider = (state) => state.home.sliders;
export const getCategorys = (state) => state.home.categories;
export const getProducts = (state) => state.home.products;
export const getCompaignProduct = (state) => state.home.compaign;
export const getBlogs = (state) => state.home.blogs;
export const selectLoading = (state) => state.home.loading;
export const selectError = (state) => state.home.error;





// export const getslider = async () => {
//   try {
//     const response = await axiosInstance.get('/home');
//     return response.data.sliders;
//   } catch (error) {
//     return handleError(error);
//   }
// };
// export const getCategorys = async () => {
//   try {
//     const response = await axiosInstance.get('/home');
//     return response.data.categories;
//   } catch (error) {
//     return handleError(error);
//   }
// };
// export const getProducts = async () => {
//   try {
//     const response = await axiosInstance.get('/home');
//     return response.data.products;
//   } catch (error) {
//     return handleError(error);
//   }
// };
// export const getBlogs = async () => {
//   try {
//     const response = await axiosInstance.get('/home');
//     return response.data.blogs;
//   } catch (error) {
//     return handleError(error);
//   }
// };
