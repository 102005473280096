import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLoading } from "../LoadingContext";
import { useToast } from "../ToastContext";
import { Link, useNavigate } from "react-router-dom";
import { getProducts } from "../../api/homeservice";
import { fetchHomeData } from "../../api/homeservice";
import iFreeDelivery from "../../images/i_free_delivery.png";
import {
  addToCart,
  selectCartError,
  clearError,
  addCartBackend,
} from "../../api/store/slices/cartSlice";
import { setDirectOrder } from "../../api/store/slices/orderSlice";
import Productmodel from "../singleproduct/productmodel";

const CategoryBasedProducts = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { showToast } = useToast();
  const [groupedProducts, setGroupedProducts] = useState({});

  // Get products and categories from Redux store
  const products = useSelector((state) => state.home.products);
  const categories = useSelector((state) => state.home.categories);
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  const [activeIndex, setActiveIndex] = useState(0);
  const [Error, setError] = useState("");
  const [modal, activemodal] = useState("none");
  const [ProductData, setProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantities, setQuantities] = useState({}); // Store quantities for each product
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const Navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const errorproduct = useSelector((state) => state.home.error);

  // Group products by category
  useEffect(() => {
    if (products.length > 0 && categories.length > 0) {
      const grouped = categories.reduce((acc, category) => {
        acc[category.id] = products
          .filter((product) => product.category_id == category.id)
          .slice(0, 10);
        return acc;
      }, {});
      setGroupedProducts(grouped);
    }
  }, [products, categories]);

  // Show error toast if there is an error fetching data
  useEffect(() => {
    if (errorproduct) {
      showToast(errorproduct, "error");
    }
  }, [errorproduct, showToast]);

  // Function to get all product images for a product
  const getProductImages = (product) => {
    const images = [
      product.featured_image,
      product.first_image,
      product.second_image,
      product.third_image,
    ].filter((img) => img); // Filter out null/undefined images

    // If no images are available, return a default image
    return images.length > 0
      ? images
      : ["assets/images/products/product-img-1.jpg"];
  };

  // Calculate price including campaign discount (fixed amount)
  const calculatePrice = (product) => {
    if (!product) return 0;

    const basePrice = parseFloat(product.price);
    if (product.product_campaign && product.product_campaign.campaign) {
      const campaign = product.product_campaign.campaign;
      const discountAmount = parseFloat(campaign.discount); // Fixed discount amount
      return Math.max(basePrice - discountAmount, 0).toFixed(2); // Ensure price doesn't go below 0
    }
    return basePrice.toFixed(2);
  };

  // Handle quick view modal
  const handleQuickView = (product) => {
    setSelectedProduct(product);
    setActiveIndex(0);
    activemodal("block");
  };

  const getQuantity = (productId) => {
    return quantities[productId] || 1; // Default to 1 if quantity is not found
  };

  // Prepare attributes in the format expected by the cart system
  // Prepare attributes to match the exact JSON structure
  const prepareAttributes = (selectedAttributes) => {
    return Object.entries(selectedAttributes).map(([attributeName, value]) => ({
      attribute_name: attributeName,
      attribute_option_id: value.attribute_option_id,
      attribute_option: value.attribute_option,
    }));
  };

  // Updated handleAddToCart function
  const handleAddToCart = async (product) => {
    console.log(quantities);

    if (!selectedProduct && !product) {
      console.error("No product found!");
      return;
    }

    const baseProduct = selectedProduct || product;
    console.log(selectedAttributes);

    // Prepare attributes
    const attributes = prepareAttributes(selectedAttributes);

    // Prepare cart item
    const cartItem = {
      id: null, // Will be assigned by cart system
      product_id: baseProduct.id,
      quantity: getQuantity(baseProduct.id) || 1, // Default quantity
      campaign_id: baseProduct.product_campaign
        ? baseProduct.product_campaign.campaign_id
        : null,
      discount_value: baseProduct.product_campaign
        ? baseProduct.product_campaign.campaign.discount
        : null,
      product: {
        id: baseProduct.id,
        product_name: baseProduct.product_name,
        price: baseProduct.price,
        featured_image: baseProduct.featured_image,
      },
      attribute_values: Object.values(selectedAttributes).map(
        (data) => data.attributeOptionId
      ),
      selectedAttributes: Object.entries(selectedAttributes).map(
        ([attributeId, data]) => ({
          attribute_id: parseInt(attributeId, 10),
          attribute_option_id: data.attributeOptionId,
          attribute_name: data.attributeName,
          attribute_option: data.optionName,
        })
      ),
    };

    console.log("Add to Cart Payload:", cartItem);

    try {
      // Attempt to sync with backend
      const result = await dispatch(addCartBackend(cartItem)).unwrap();

      // Dispatch the action to add the item to Redux state only if backend sync is successful
      dispatch(addToCart(cartItem));
      console.log("Cart synced successfully with backend:", result);

      // Clear selections after successful addition
      if (selectedProduct) {
        setSelectedProduct(null);
        setSelectedAttributes({});
        setQuantities({});
        activemodal("none");
      }
    } catch (error) {
      // Handle errors during backend sync
      console.error("Failed to sync cart:", error);
      showToast(error, "error");
      setError(error, "error");
    }
  };

  const handleQuantityChange = (productId, value) => {
    setQuantities((prev) => ({
      ...prev,
      [productId]: Math.max(1, (prev[productId] || 1) + value),
    }));
  };

  const handleAttributeSelect = (
    attributeId,
    attributeOptionId,
    optionName,
    attributeName
  ) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [attributeId]: {
        attributeOptionId,
        optionName,
        attributeName,
      },
    }));
  };

  const CheckoutDirect = (product) => {
    if (!selectedProduct && !product) {
      console.error("No product found!");
      return;
    }

    const baseProduct = selectedProduct || product;

    // Prepare cart item
    const items = {
      id: null, // Will be assigned by cart system
      product_id: baseProduct.id,
      quantity: getQuantity(baseProduct.id) || 1, // Default quantity
      campaign_id: baseProduct.product_campaign
        ? baseProduct.product_campaign.campaign_id
        : null,
      discount_value: baseProduct.product_campaign
        ? baseProduct.product_campaign.campaign.discount
        : null,
      product: {
        id: baseProduct.id,
        product_name: baseProduct.product_name,
        price: baseProduct.price,
        featured_image: baseProduct.featured_image,
      },
      attribute_values: Object.values(selectedAttributes).map(
        (data) => data.attributeOptionId
      ),
      attributes: Object.entries(selectedAttributes).map(
        ([attributeId, data]) => ({
          attribute_id: parseInt(attributeId, 10),
          attribute_option_id: data.attributeOptionId,
          attribute_name: data.attributeName,
          attribute_option: data.optionName,
        })
      ),
    };

    dispatch(setDirectOrder(items));
    Navigate("/checkout");
  };

  if (loading) {
    return (
      <div className="container text-center py-8">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  function formatPrice(price) {
    const formattedPrice = parseFloat(
      price.toString().replace("Tk", "")
    ).toFixed(2); // Remove 'Tk' and ensure numeric format
    return formattedPrice.endsWith(".00")
      ? formattedPrice.slice(0, -3) // Remove .00 if present
      : formattedPrice;
  }

  function formatOldPrice(price) {
    if (price === null || price === undefined || price === "") return ""; // Return empty string for null or empty values
  
    const formattedPrice = parseFloat(
      price.toString().replace("Tk", "").trim()
    ).toFixed(2);
  
    return formattedPrice.endsWith(".00")
      ? formattedPrice.slice(0, -3)
      : formattedPrice;
  }
  
  

  return (
    <div className="container" style={{ marginTop: "70px" }}>
      {categories.map((category) => {
        const categoryProducts = (groupedProducts[category.id] || []).slice(
          0,
          8
        );

        if (categoryProducts.length === 0) return null;

        return (
          <div key={category.id} className="mb-10">
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="mb-0">{category.name}</h3>
              <Link
                to={`/filter?category=${category.slug}`}
                className="btn btn-outline-primary btn-sm"
              >
                View All
              </Link>
            </div> */}

            <div className="col-md-12 row productbaseofctg">
              <div className="col-md-4">
                <div key={category.id} className="col single-item">
                  <Link
                    to={`/filter?category=${category.slug}`}
                    className="text-decoration-none text-reset"
                  >
                    <div className="card h-100 border-0 shadow-sm">
                      <div className="card-body text-center ">
                        <img
                          src={category?.image}
                          alt={category?.name}
                          className="img-fluid"
                        />
                        <div className="position-absulate">
                          <span>{category?.name}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-8">
                <div
                  className="d-grid gap-5 popular-products items-list"
                  style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
                >
                  {categoryProducts.map((product) => (
                    <div className="single-items" key={product.id}>
                      <div className="card card-product">
                        <div className="card-body">
                          <div className="text-center position-relative">
                              {/* Display out of stock badge */}
                              {product.quantity == 0 && (
                                <div className="outofstockbage">
                                  <div className="signoftk">Out of stock</div>
                                </div>
                              )}
                            

                            {/* Check for product campaign and display discount */}
                            {product?.product_campaign && (
                              <div className="position-absolute top-0 start-0 z-2">
                                <span className="d-flex badge bg-danger">
                                  <div className="signoftk">Save ৳</div>
                                  {product.product_campaign.campaign.discount}
                                </span>
                              </div>
                            )}

                            {/* Check for free shipping and display free delivery badge */}
                            {product?.is_free_shipping == 1 && (
                              <div
                                className={`position-absolute top-0 start-0 z-2 ${
                                  product?.product_campaign ? "pt-5" : "pt-0"
                                }`}
                              >
                                <span
                                  className="d-flex badge"
                                  style={{ background: "black" }}
                                >
                                  <div className="signoftk">
                                    <img
                                      src={iFreeDelivery}
                                      style={{ width: "15px", height: "15px" }}
                                    />
                                    Free delivery
                                  </div>
                                </span>
                              </div>
                            )}
                            <Link
                              to={`/product/${product.slug}/#`}
                              onClick={(e) => window.scrollTo(0, 0)}
                            >
                              <img
                                src={
                                  product.featured_image ||
                                  "assets/images/products/product-img-1.jpg"
                                }
                                alt={product.product_name}
                                className="img-fluid ratio"
                              />
                            </Link>
                            {/* <div className="card-product-action">
                                                      <a
                                                        href="#!"
                                                        className="btn-action"
                                                        onClick={(e) => handleQuickView(product)}
                                                      >
                                                        <i className="bi bi-eye"></i>
                                                      </a>
                                                      <a href="#!" className="btn-action mx-1">
                                                        <i className="bi bi-heart"></i>
                                                      </a>
                                                      <a href="#!" className="btn-action">
                                                        <i className="bi bi-arrow-left-right"></i>
                                                      </a>
                                                    </div> */}
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="price">
                              <span className="text-dark">
                                <div className="signoftk">৳</div>
                                    {formatPrice(calculatePrice(product))}{" "}
                                <div className="old-price-product-cd">
                                  {/* <span>৳</span> */}
                                  <span>
                                    {formatOldPrice(product.previous_price)}
                                  </span>
                                </div>
                                {product.product_campaign && (
                                  <div className="d-flex text-decoration-line-through text-muted ms-2">
                                    <span className="signoftk">৳</span>
                                    {formatPrice(product.price)}
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* Quick View Modal */}

      <div
        className="modal fade show"
        style={{ display: modal }}
        id="quickViewModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-8">
              {selectedProduct && (
                <>
                  <div
                    className="position-absolute top-0 end-0 me-3 mt-3"
                    onClick={() => activemodal("none")}
                  >
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <section className="mt-8 single-product-viewpage">
                    <div className="container">
                      <div className="row">
                        <Productmodel selectedProduct={selectedProduct} />
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal-backdrop fade show"
        style={{ display: modal }}
      ></div>
    </div>
  );
};

export default CategoryBasedProducts;
