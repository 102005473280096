import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHouse, 
  faMagnifyingGlass, 
  faUser, 
  faCartShopping, 
  faLocationDot 
} from '@fortawesome/free-solid-svg-icons';
import { setActiveCart } from '../../api/store/slices/cartSlice';
import SearchCard from './search';
import Headercart from './headercart';

export default function MobileHeader() {
  const [activeLink, setActiveLink] = useState(window.location.pathname || '');
  const [isSearchOpen, setSearchOpen] = useState(false);
  const { activecart, items } = useSelector((state) => state.cart);
  const [cartCount, setCartCount] = useState(items.length || 0);
  const dispatch = useDispatch();
  const [cartList, activecartList] = useState(0);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const addCartSection = () => {
    dispatch(setActiveCart(true));
  };

  return (
    <>
      <nav className="mobile-menu">
        {/* Search Icon */}
        <li className={activeLink === 'filter' ? 'active-menu' : ''}>
          <Link 
            to="#" 
            onClick={() => {
              handleLinkClick('filter');
              toggleSearch();
            }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: '#333' }} />
            <label>Search</label>
          </Link>
        </li>

        {/* Track Order */}
        <li className={activeLink === '/order/tracking' ? 'active-menu' : ''}>
          <Link to="/order/tracking" onClick={() => handleLinkClick('/order/tracking')}>
            <FontAwesomeIcon icon={faLocationDot} style={{ color: '#333' }} />
            <label>Track Order</label>
          </Link>
        </li>

        {/* Cart Icon */}
        <li className={activeLink === 'cart' ? '' : ''}>
          <Link 
            to="#" 
            onClick={() => {
              handleLinkClick('cart');
              addCartSection();
            }}
          >
            <div className="cart-controller">
              <FontAwesomeIcon icon={faCartShopping} style={{ color: '#333' }} />
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                {cartCount}
              </span>
            </div>
            <label>Cart</label>
          </Link>
        </li>

        {/* User Icon */}
        <li className={activeLink === '/signin' ? 'active-menu' : ''}>
          <Link to="/signin" onClick={() => handleLinkClick('/signin')}>
            <FontAwesomeIcon icon={faUser} style={{ color: '#333' }} />
            <label>Login</label>
          </Link>
        </li>

        {/* Home Link */}
        <li className={activeLink === '/' ? 'active-menu' : ''}>
          <Link to="/" onClick={() => handleLinkClick('/')}>
            <FontAwesomeIcon icon={faHouse} style={{ color: '#333' }} />
            <label>Home</label>
          </Link>
        </li>

        {/* Full-page search overlay */}
        {isSearchOpen && (
          <div className="search-overlay">
            <SearchCard />
            <button className="close-search" onClick={toggleSearch}>
              Close
            </button>
          </div>
        )}
      </nav>

      <div
        className={`offcanvas offcanvas-end ${cartList === 1 || activecart ? 'show' : ''}`}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Headercart />
      </div>
    </>
  );
}
