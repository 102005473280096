import React from 'react'
import Product from '../components/singleproduct/product'
import Header from '../components/Header'
import Footer from '../components/Footer'
export default function Productpage() {
  return (
    <>
         <Header/>
         <Product/>
         <Footer/>
      </>
  )
}
