"use client"

import Header from "../components/Header"
import Footer from "../components/Footer"
import { XCircle, RefreshCw, MessageCircle, ArrowLeft, AlertCircle } from "lucide-react"
// import axiosInstance from "../api/axiosInstance";
import { useEffect, useState } from "react"

export default function PaymentError() {
  const [paymentDetails, setPaymentDetails] = useState({
    code: "",
    message: "",
    paymentId: "",
    orderId: "",
  })

  const [isCancelled, setIsCancelled] = useState(false)

  useEffect(() => {
    // Parse URL parameters
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get("code") || ""
    const message = searchParams.get("message") || ""
    const paymentId = searchParams.get("payment_id") || ""
    const orderId = searchParams.get("order_id") || ""

    setPaymentDetails({ code, message, paymentId, orderId })
    setIsCancelled(code === "PAYMENT_CANCELLED")
  }, [])

  return (
    <>
      <Header />
      <div className="payment-error-page container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="card border-0 shadow-sm">
              <div className="card-body text-center p-5">
                <div className="mb-4">
                  {isCancelled ? (
                    <AlertCircle className="text-warning" size={80} strokeWidth={1.5} />
                  ) : (
                    <XCircle className="text-danger" size={80} strokeWidth={1.5} />
                  )}
                </div>

                <h2 className="mb-3 fw-bold">{isCancelled ? "Payment Cancelled" : "Payment Failed"}</h2>

                <p className="text-muted mb-4">
                  {isCancelled
                    ? "You cancelled the payment process. No charges have been made to your account."
                    : "We couldn't process your payment. This could be due to insufficient funds, expired card details, or your bank declining the transaction."}
                </p>

                {/* {paymentDetails.paymentId && paymentDetails.orderId && (
                  <div className="alert alert-light border mb-4 text-start">
                    <p className="mb-1 small">
                      <strong>Payment ID:</strong> {paymentDetails.paymentId}
                    </p>
                    <p className="mb-0 small">
                      <strong>Order ID:</strong> {paymentDetails.orderId}
                    </p>
                  </div>
                )} */}

                <div className="alert alert-light border mb-4">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <span className="badge bg-danger rounded-circle p-2">!</span>
                    </div>
                    <div className="text-start small">
                      {isCancelled
                        ? "Your payment was cancelled and your card has not been charged. You can try again whenever you're ready."
                        : "Your card has not been charged. You can try again with the same card or use a different payment method."}
                    </div>
                  </div>
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-4">
                  <button className="btn btn-primary px-4 py-2 d-flex align-items-center">
                    <RefreshCw size={18} className="me-2" />
                    Try Again
                  </button>
                  <button className="btn btn-outline-secondary px-4 py-2 d-flex align-items-center">
                    <MessageCircle size={18} className="me-2" />
                    Contact Support
                  </button>
                </div>

                <a href="/" className="d-inline-flex align-items-center text-decoration-none">
                  <ArrowLeft size={16} className="me-2" />
                  Return to Homepage
                </a>
              </div>
            </div>

            <div className="card mt-4 border-0 shadow-sm">
              <div className="card-body p-4">
                <h5 className="card-title mb-3">Common Payment Issues</h5>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item bg-transparent px-0">
                    <strong>Insufficient funds</strong> - Ensure your account has enough balance
                  </li>
                  <li className="list-group-item bg-transparent px-0">
                    <strong>Card expired</strong> - Check if your card details are up to date
                  </li>
                  <li className="list-group-item bg-transparent px-0">
                    <strong>Bank restrictions</strong> - Some banks may block online transactions
                  </li>
                  <li className="list-group-item bg-transparent px-0">
                    <strong>Network issues</strong> - Try again when you have a stable connection
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

