import React, { useEffect } from "react";
import ReactSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from 'react-redux';
import { useLoading } from "../LoadingContext"; // Adjust the import path
import { useToast } from "../ToastContext";
import { Link } from "react-router-dom";
import { fetchHomeData } from "../../api/homeservice";

export default function FeatureCategories() {
  const dispatch = useDispatch();
  const { setLoading } = useLoading(); // Access setLoading
  const { showToast } = useToast();

  // Get categories from Redux store
  const categories = useSelector((state) => state.home.categories.slice(0, 12));
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  

  // Fetch categories once when component mounts
  useEffect(() => {
    if (categories.length === 0) {
      // // dispatch(fetchHomeData()); 
    }
  }, [dispatch, categories.length]);

  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <button className="slick-prev"><i className="feather-icon icon-chevron-left"></i></button>,
    nextArrow: <button className="slick-next"><i className="feather-icon icon-chevron-right"></i></button>,
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 5 }
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 4 }
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 576,
        settings: { slidesToShow: 2 }
      }
    ]
  };

  function formatPrice(price) {
    const formattedPrice = parseFloat(
      price.toString().replace("Tk", "")
    ).toFixed(2); // Remove 'Tk' and ensure numeric format
    return formattedPrice.endsWith(".00")
      ? formattedPrice.slice(0, -3) // Remove .00 if present
      : formattedPrice;
  }
  return (
    <>
      <section className="mb-lg-10 mt-lg-14 my-8 categorys">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-6">
              <h3 className="mb-0 text-center text-[#FF6E00]">TOP CATEGORIES</h3>
            </div>
          </div>
          <div className="list-items category-slider mt-4 row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-6">
  {loading ? (
    <div>Loading...</div> // Show loading state
  ) : (
    <>
      {categories.map((ctg) => (
        <div key={ctg.id} className="col single-item">
          <Link to={`/filter?category=${ctg.slug}`} className="text-decoration-none text-reset">
            <div className="card h-100 border-0 shadow-sm">
              <div className="card-body text-center ">
                <img
                  src={ctg?.image}
                  alt={ctg?.name}
                  className="img-fluid"
                  
                />
                <div className="position-absulate"><span>{ctg?.name}</span></div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  )}
</div>


        </div>
      </section>

      {/* <section>
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 col-md-6 mb-3 mb-lg-0">
              <div className="py-10 px-8 rounded" style={{ background: "url('assets/images/banner/grocery-banner.png')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <h3 className="fw-bold mb-1">Fruits & Vegetables</h3>
                <p className="mb-4">Get Upto <span className="fw-bold">30%</span> Off</p>
                <a href="#!" className="btn btn-dark">Shop Now</a>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="py-10 px-8 rounded" style={{ background: "url('assets/images/banner/grocery-banner-2.png')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <h3 className="fw-bold mb-1">Freshly Baked Buns</h3>
                <p className="mb-4">Get Upto <span className="fw-bold">25%</span> Off</p>
                <a href="#!" className="btn btn-dark">Shop Now</a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
