import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../LoadingContext";
import { useToast } from "../ToastContext";

export default function DailyBestSells() {
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { showToast } = useToast();

  // Fetching blogs data from Redux
  const blogsData = useSelector((state) => state.home.blogs.slice(0, 4));
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  // Fetch blogs if not loaded
  useEffect(() => {
    if (blogsData.length === 0) {
      // Uncomment this when API integration is ready
      // dispatch(fetchHomeData());
    }
  }, [dispatch, blogsData]);

  // Show toast for error
  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  // Loading state
  if (loading) {
    return <div className="text-center py-5">Loading...</div>;
  }

  // If no blogs are available
  if (!blogsData.length) {
    return <div className="text-center py-5"></div>;
  }

  return (
    <div className="container py-5">
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="section-title">
            <h2 className="h3 ">Our Blog</h2>
          </div>
        </div>
      </div>

      <div className="row justify-content-start blog-list" >
        {blogsData.map((blog) => (
          <div
            key={blog.id}
            className="col-lg-3 col-md-4 col-sm-12 mb-4 d-flex blog-single"
          >
            <div className="card h-100 shadow-sm w-100">
              <a href={`/blogs/${blog.id}`}>
                <img
                  src={blog.image || "assets/images/default-blog.jpg"}
                  alt={blog.title}
                  className="card-img-top img-fluid "
                />
              </a>
              <div className="card-body text-center">
                <h5 className="card-title">
                  <a
                    href={`/blogs/${blog.id}`}
                    className="text-decoration-none text-dark"
                  >
                    {blog.title}
                  </a>
                </h5>
              </div>
           
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
