import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLoading } from "../LoadingContext"; // Adjust the import path
import { useToast } from "../ToastContext";
import { getCategorys } from "../../api/homeservice"; // Adjust the import path as necessary
import Headercart from "./headercart";
import Search from "./search";
import { setActiveCart } from "../../api/store/slices/cartSlice";
import { fetchHomeData } from "../../api/homeservice";
import { fetchCartData } from "../../api/store/slices/cartSlice"; // Import the action

export default function Middle() {
  const [Locationmodal, activeLocationmodal] = useState("none");
  const [userModal, activeuserModal] = useState("none");
  const [cartList, activecartList] = useState(0);

  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const { setLoading } = useLoading();
  const { showToast } = useToast();
  const { user } = useSelector((state) => state.user);
  const { activecart, items } = useSelector((state) => state.cart); // Get items from the cart state
  const dispatch = useDispatch();

  // Get cart item count from Redux store
  const cartCount = items.length; // Simply use the length of the items array

  // Get categories from Redux store
  const categories = useSelector((state) => state.home.categories);
  const siteinfos = useSelector((state) => state.home?.siteinfos[0]);
  const media = useSelector((state) => state.home?.siteinfos?.media || []);
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  // Fetch cart data when the component mounts
  useEffect(() => {
    dispatch(fetchCartData());
  }, [dispatch]);

  // Filtered categories based on the search query
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const removeCartSection = () => {
    dispatch(setActiveCart(false));
  };
  const addCartSection = () => {
    dispatch(setActiveCart(true));
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="pt-5 header-middle">
        <div className="container">
          <div className="row w-100 align-items-center gx-lg-2 gx-0 justify-content-between">
            <div className="col-xxl-2 col-lg-3 col-md-3 col-5 logo">
              <Link className="navbar-brand d-none d-lg-block" to="/">
                <img
                  src={
                    media[0]?.logo ??
                    ""
                  }
                  alt={siteinfos?.app_name ?? ""}
                />
              </Link>
              <div className="d-flex justify-content-between w-100 d-lg-none">
                <Link className="navbar-brand" to="/">
                  <img
                    src={
                      media[0]?.logo ??
                      ""
                    }
                    alt={siteinfos?.app_name ?? ""}
                  />
                </Link>
              </div>
            </div>
            <Search />
           
            <div className="col-lg-2 col-xxl-2 text-end col-md-6 col-7">
              <div className="list-inline">
                <div className="list-inline-item me-5">
                  <Link
                    to="/order/tracking"
                    className="text-muted position-relative"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-geo-alt-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                    </svg>
                  </Link>
                </div>
                <div className="list-inline-item me-5">
                  <Link
                    to={user ? "/account-orders" : "/signin"}
                    className="text-muted"
                    data-bs-toggle="modal"
                    data-bs-target="#userModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </Link>
                </div>
                <div className="list-inline-item me-5 me-lg-0">
                  <a
                    className="text-muted position-relative"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasRight"
                    onClick={addCartSection}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-shopping-bag"
                    >
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      {cartCount}
                    </span>
                  </a>
                </div>
                <div className="list-inline-item d-inline-block d-lg-none">
                  <button
                    className="d-lg-none border-0 bg-transparent"
                    style={{ zIndex: 1060 }}
                    onClick={toggleSidebar}
                    aria-label="Toggle menu"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-text-indent-left text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`offcanvas offcanvas-end ${
           activecart ? "show" : ""
        }`}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Headercart />
      </div>
      <div
        className="modal-backdrop fade show"
        onClick={removeCartSection}
        style={{ display:  activecart ? "block" : "none" }}
      ></div>
      <div
        className="modal fade show"
        style={{ display: Locationmodal }}
        id="locationModal"
        aria-labelledby="locationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-6">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h5 className="mb-1" id="locationModalLabel">
                    Choose your Delivery Location
                  </h5>
                  <p className="mb-0 small">
                    Enter your address and we will specify the offer you area.
                  </p>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => activeLocationmodal("none")}
                ></button>
              </div>
              <div className="my-5">
                {/* Search Input */}
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search categories"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                />
                <div className="mt-5">
                  <div
                    data-simplebar
                    style={{
                      height: "300px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="list-group list-group-flush">
                      
                      {filteredCategories.map((category, index) => (
                        <Link
                          key={index}
                          to={"/product/" + category.slug}
                          className={`list-group-item d-flex justify-content-between align-items-center px-2 py-3 list-group-item-action ${
                            index === 0 ? "active" : ""
                          }`}
                        >
                          <span>
                            <img
                              src={category.image || "placeholder-image-url"} // Replace with a fallback URL if necessary
                              alt={category.name}
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "10px",
                              }}
                            />
                            {category.name}
                          </span>
                        </Link>
                      ))}
                      {filteredCategories.length === 0 && (
                        <div className="text-center text-muted mt-3">
                          No categories found.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        style={{ display: Locationmodal }}
      ></div>
      <div
        className="modal-backdrop fade show"
        style={{ display: userModal }}
      ></div>
      <div
        className="modal-backdrop fade show "
        style={{ display: cartList === 1 ? "block" : "none" }}
      ></div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="position-fixed top-0 start-0 h-100 w-100 bg-dark"
          style={{
            opacity: 0.5,
            transition: "opacity 0.3s ease-in-out",
            zIndex: 1040,
          }}
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar */}
      <div
        className="position-fixed top-0 start-0 h-100 bg-white"
        style={{
          width: "300px",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1050,
          transform: isOpen ? "translateX(0)" : "translateX(-100%)",
        }}
      >
        {/* Menu Items */}
        <div className="overflow-auto p-3" style={{ height: "calc(100% - 70px)" }}>

          <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex justify-content-between w-100 d-lg-none">
                <Link className="navbar-brand sidebar-logo" to="/">
                  <img
                    src={
                      media[0]?.logo ??
                      "../../../assets/images/logo/1727679220.webp"
                    }
                    alt={siteinfos?.app_name ?? ""}
                  />
                </Link>
              </div>
          </div>
          <hr />

          <div className="list-group list-group-flush">
            {filteredCategories.map((category, index) => (
              <Link
                key={index}
                to={"/filter/?category=" + category.slug}
                className="list-group-item d-flex justify-content-between align-items-center px-2 py-3"
              >
                <span>
                  {category.name}
                </span>
              </Link>
            ))}
            {filteredCategories.length === 0 && (
              <div className="text-center text-muted mt-3">
                No categories found.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
