import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Forget from '../components/auth/forget'
export default function Forgetpassword() {
  return (
   <>
    <Header/>
    <main>
        <Forget/>
    </main>
    <Footer/>
   </>
  )
}
