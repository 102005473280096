import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Signin from '../components/auth/signin'
export default function SigninPage() {
  return (
    <>
    <Header/>
    <Signin/>
    <Footer/>
    </>
  )
}
