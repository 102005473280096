import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useSelector } from 'react-redux';
import AppRoute from './AppRoute';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import authReducer from './api/authService';
import userReducer from './api/store/slices/userSlice';
import cartReducer from './api/store/slices/cartSlice';
import orderReducer from './api/store/slices/orderSlice';
import homeReducer from './api/homeservice';
import InitializeAuth from './InitializeAuth';

// Configure Redux store
const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    cart: cartReducer,
    order: orderReducer,
    home: homeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Component to handle marketing scripts
const MarketingScripts = () => {
  const siteInfos = useSelector((state) => state.home.siteinfos);
  const marketingTags = siteInfos?.marketing || [];
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && marketingTags.length > 0) {
      marketingTags.forEach((tag) => {
        if (!tag.script) return; // Skip if there's no script content
  
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = tag.script.trim(); // Convert script string into DOM elements
  
        // Extract and append all <script> elements properly
        tempDiv.querySelectorAll('script').forEach((scriptTag) => {
          const newScript = document.createElement('script');
          newScript.type = 'text/javascript';
  
          if (scriptTag.src) {
            // External script: set src
            newScript.src = scriptTag.src;
            newScript.async = true;
          } else {
            // Inline script: copy content
            newScript.text = scriptTag.innerHTML;
          }
  
          document.head.appendChild(newScript);
        });
  
        // Extract and append all <noscript> elements properly
        tempDiv.querySelectorAll('noscript').forEach((noscriptTag) => {
          document.body.appendChild(noscriptTag);
        });
      });
  
      initialized.current = true;
    }
  }, [marketingTags]);
  

  return null;
};


// Main App component
const App = () => {
  return (
    <Provider store={store}>
      <InitializeAuth>
       
        <MarketingScripts />
        <AppRoute /> {/* Main application routes */}
      </InitializeAuth>
    </Provider>
  );
};

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Report web vitals
reportWebVitals();