"use client"

import { useEffect, useState } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { CheckCircle, ShoppingBag, Home, Printer } from "lucide-react"
import axiosInstance from "../api/axiosInstance"

export default function PaymentSuccess() {
  const [orderData, setOrderData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [transactionDetails, setTransactionDetails] = useState({
    orderId: "",
    invoiceNumber: "",
    trxId: "",
    amount: "",
    currency: "",
  })

  useEffect(() => {
    // Parse URL parameters
    const searchParams = new URLSearchParams(window.location.search)
    const orderId = searchParams.get("order_id") || ""
    const invoiceNumber = searchParams.get("invoice_number") || ""
    const trxId = searchParams.get("trx_id") || ""
    const amount = searchParams.get("amount") || ""
    const currency = searchParams.get("currency") || ""

    setTransactionDetails({ orderId, invoiceNumber, trxId, amount, currency })

    const fetchOrderData = async () => {
      try {
        // Use trxId from URL instead of localStorage
        if (!trxId) {
          throw new Error("Transaction ID not found in URL")
        }

        // Use the transaction ID from URL to fetch order data
        const response = await axiosInstance.get(`/order-data/${trxId}`)
        setOrderData(response.data)
      } catch (err) {
        setError(err.message || "Something went wrong")
      } finally {
        setLoading(false)
      }
    }

    fetchOrderData()
  }, [])

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
  }

  const calculateSubtotal = () => {
    return orderData.order_items.reduce((sum, item) => {
      return sum + Number(item.price) * item.quantity
    }, 0)
  }

  // Function to handle printing
  const handlePrint = () => {
    const printContent = document.getElementById("order-summary").innerHTML
    const originalContent = document.body.innerHTML

    // Temporarily replace the body content with only the order summary
    document.body.innerHTML = `
      <div style="padding: 20px;">
        <h2 style="text-align: center;">Order Summary</h2>
        ${printContent}
      </div>
    `
    window.print()
    document.body.innerHTML = originalContent // Restore original content
    window.location.reload() // Reload to ensure React state is preserved
  }

  if (loading) {
    return (
      <>
        <Header />
        <div className="container py-5 text-center">
          <p>Loading...</p>
        </div>
        <Footer />
      </>
    )
  }

  if (error) {
    return (
      <>
        <Header />
        <div className="container py-5 text-center">
          <div className="alert alert-danger">
            <p className="mb-0">Error: {error}</p>
            <p className="mt-2 mb-0">
              <small>Transaction ID: {transactionDetails.trxId || "Not available"}</small>
            </p>
          </div>
        </div>
        <Footer />
      </>
    )
  }

  // const totalAmount =
  //   Number(orderData.total_price) +
  //   Number(orderData.shipping_price) +
  //   Number(orderData.delivery_charge) -
  //   Number(orderData.discount);

  return (
    <>
      <Header />
      <div className="payment-success-page container py-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="card border-0 shadow-sm mb-4">
              <div className="card-body text-center p-5">
                <div className="mb-4">
                  <CheckCircle className="text-success" size={80} strokeWidth={1.5} />
                </div>

                <h2 className="mb-2 fw-bold">Payment Successful!</h2>
                <p className="text-muted mb-4">
                  Thank you for your purchase. Your transaction has been completed successfully.
                </p>

                <div className="alert alert-success border mb-4">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <span className="badge bg-success rounded-circle p-2">✓</span>
                    </div>
                    <div className="text-start small">
                      Transaction ID: <strong>{orderData.transaction_id || transactionDetails.trxId}</strong>
                    </div>
                  </div>
                </div>

                <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-3">
                  <button
                    className="btn btn-primary px-4 py-2 d-flex align-items-center"
                    onClick={handlePrint} // Add the print handler here
                  >
                    <Printer size={18} className="me-2" />
                    Print Receipt
                  </button>
                </div>

                <div className="d-flex justify-content-center gap-3 mt-3">
                  <a href="/" className="text-decoration-none d-flex align-items-center">
                    <Home size={16} className="me-1" />
                    Home
                  </a>
                  <a href="/shop" className="text-decoration-none d-flex align-items-center">
                    <ShoppingBag size={16} className="me-1" />
                    Continue Shopping
                  </a>
                </div>
              </div>
            </div>

            <div className="card border-0 shadow-sm" id="order-summary">
              <div className="card-header bg-white py-3">
                <h5 className="mb-0">Order Summary</h5>
              </div>
              <div className="card-body p-4">
                <div className="row mb-3">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <h6 className="text-muted mb-2">Order Number</h6>
                    <p className="mb-0 fw-bold">{orderData.invoice_number || transactionDetails.invoiceNumber}</p>
                  </div>
                  <div className="col-md-6">
                    <h6 className="text-muted mb-2">Order Date</h6>
                    <p className="mb-0">{formatDate(orderData.created_at)}</p>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <h6 className="text-muted mb-2">Payment Method</h6>
                    <p className="mb-0 text-capitalize">{orderData.payment_method}</p>
                  </div>
                  <div className="col-md-6">
                    <h6 className="text-muted mb-2">Payment Status</h6>
                    <p className="mb-0">
                      <span className="badge bg-success text-capitalize">{orderData.payment_status}</span>
                    </p>
                  </div>
                </div>

                <h6 className="mb-3">Items</h6>
                <div className="table-responsive mb-4">
                  <table className="table table-borderless">
                    <thead className="table-light">
                      <tr>
                        <th>Item</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-end">Price</th>
                        <th className="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.order_items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center flex-wrap">
                              <img
                                src={item.product.featured_image || "/placeholder.svg"}
                                alt={item.product.product_name}
                                className="me-2"
                                style={{ width: "50px", height: "50px", objectFit: "cover" }}
                              />
                              <div>
                                <div className="fw-bold">{item.product.product_name}</div>
                                <small className="text-muted">Code: {item.product.product_code}</small>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">{item.quantity}</td>
                          <td className="text-end">${Number(item.price).toFixed(2)}</td>
                          <td className="text-end">${(Number(item.price) * item.quantity).toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr className="border-top">
                        <td colSpan={3} className="text-end">
                          Subtotal:
                        </td>
                        <td className="text-end">${calculateSubtotal().toFixed(2)}</td>
                      </tr>
                      {/* <tr>
                        <td colSpan={3} className="text-end">
                          Shipping:
                        </td>
                        <td className="text-end">${Number(orderData.shipping_price).toFixed(2)}</td>
                      </tr> */}
                      <tr>
                        <td colSpan={3} className="text-end">
                          Delivery Charge:
                        </td>
                        <td className="text-end">${Number(orderData.delivery_charge).toFixed(2)}</td>
                      </tr>
                      {Number(orderData.discount) > 0 && (
                        <tr>
                          <td colSpan={3} className="text-end text-success">
                            Discount:
                          </td>
                          <td className="text-end text-success">-${Number(orderData.discount).toFixed(2)}</td>
                        </tr>
                      )}
                      <tr className="fw-bold">
                        <td colSpan={3} className="text-end">
                          Total:
                        </td>
                        <td className="text-end">${Number(orderData.total_price).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <h6 className="text-muted mb-2">Shipping Address</h6>
                    <p className="mb-0">
                      {orderData.customer_name}
                      <br />
                      {orderData.address}
                      <br />
                      Phone: {orderData.phone_number}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6 className="text-muted mb-2">Order Status</h6>
                    <p className="mb-0">
                      <span
                        className={`badge ${
                          orderData.order_status === "pending" ? "bg-warning" : "bg-success"
                        } text-capitalize`}
                      >
                        {orderData.order_status}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

