import React, { useEffect } from 'react'
import { use } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchHomeData } from '../api/homeservice'

export default function Footer() {
  const dispatch=useDispatch();
  const siteInfo = useSelector(state => state.home.siteinfos[0]) // Assuming siteInfo is in Redux store
  const media = useSelector(state => state.home.siteinfos?.media || []);
  const pages = useSelector(state => state.home.siteinfos.pages)
  useEffect(() => {
    // siteinfo check if not then dispatch fetchHomeData
    if (!siteInfo) {
      dispatch(fetchHomeData());
    }    
}, [])
// if (!siteInfo) {
//     return null;
// }

  return (
    <>
    <footer className="bg-zinc-800 footer">
        <div className='container'>
    
        <div className="flex flex-col items-center justify-between gap-6 py-8 text-white md:flex-row">
            {/* Logo Section */}
            <div className='col-md-3'>
            <div className="d-flex items-center gap-2">
                <img src={media[0]?.logo || 'default-logo.png'} alt="logo" style={{width:'50%'}} />
              
            </div>
            </div>
        </div>
            
        <div className="d-flex justify-content-between">
            {/* Contact Us Section */}
            <div className="space-y-4">
                <h4 className="text-lg font-semibold text-white">Contact Us</h4>
                <div className="space-y-2 text-gray-200">
                    <div className="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="h-5 w-5">
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                            </path>
                        </svg>
                        <a href={`tel:${siteInfo?.store_phone_number}`} className="hover:underline">
                            {siteInfo?.store_phone_number}
                        </a>
                    </div>
                    <div className="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="h-5 w-5">
                            <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                        </svg>
                        <a href={`mailto:${siteInfo?.store_email}`} className="hover:underline">
                            {siteInfo?.store_email}
                        </a>
                    </div>
                    <div className="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="h-5 w-5">
                            <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
                            <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span>{siteInfo?.address}</span>
                    </div>
                </div>
            </div>

            {/* Business Hours Section - Static for now, can be made dynamic if needed */}
            <div className="space-y-4" style={{ width: '25%' }}>
                <h4 className="text-lg font-semibold text-white">Business Hours</h4>
                <div className="space-y-2 text-gray-200">
                    <span>
                        <span>Saturday - Thursday:</span><span className="ml-2">10:00 AM - 08:00 PM</span>
                    </span>
                </div>
            </div>

            {/* Useful Links Section */}
            <div className="space-y-4">
                <h4 className="text-lg font-semibold text-white">Useful Links</h4>
                <div className="grid grid-cols-2 gap-4 text-gray-200">
                    {pages?.map((page) => (
                        <Link 
                            key={page.id} 
                            className="hover:underline" 
                            to={`/${page.type}`}
                        >
                            {page.type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                        </Link>
                    ))}
                </div>
            </div>

            {/* Social Media Section */}
            <div className="space-y-4">
                <h4 className="text-lg font-semibold text-white">Follow Us</h4>
                <div className="flex items-center gap-4 text-gray-200 social-list">
                    {/* Facebook */}
                    <a href={siteInfo?.facebook_url} aria-label="Facebook">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="h-6 w-6">
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                        </svg>
                    </a>

                    {/* Twitter/X */}
                    <a href={siteInfo?.x_url} aria-label="Twitter">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="h-6 w-6">
                            <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                        </svg>
                    </a>

                    {/* YouTube */}
                    <a href={siteInfo?.youtube_url} aria-label="YouTube">
                        <svg xmlns="http://www.w3.org/2000/svg" className="size-6" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <path d="M10.5 9.908v4.184a.41.41 0 0 0 .412.408a.4.4 0 0 0 .228-.068l3.175-2.074a.405.405 0 0 0 .003-.678l-3.175-2.11a.415.415 0 0 0-.573.11a.4.4 0 0 0-.07.228"></path>
                                <path d="M2 12c0-3.3 0-4.95 1.464-5.975C4.93 5 7.286 5 12 5s7.071 0 8.535 1.025S22 8.7 22 12s0 4.95-1.465 5.975C19.072 19 16.714 19 12 19s-7.071 0-8.536-1.025S2 15.3 2 12"></path>
                            </g>
                        </svg>
                    </a>

                    {/* LinkedIn */}
                    <a href={siteInfo?.instagram_url} aria-label="LinkedIn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="h-6 w-6">
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect width="4" height="12" x="2" y="9"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 border-t border-gray-300 pt-6 text-sm text-white border-top">
            <div className="mx-auto flex w-full max-w-[1440px] flex-col items-center justify-between gap-6 px-4 text-white sm:px-6 md:flex-row md:px-8">
                <p className="w-full text-center"> 
                    All rights reserved {siteInfo?.app_name} © 2024. Developed By <a className="hover:underline" href="https://softexel.com/" target="_blank" style={{color:'white'}}>Softexel</a> 
                </p>
            </div>
        </div>
    </footer>

    {/* WhatsApp and Scroll to Top buttons */}
    <a href={`https://wa.me/${siteInfo?.whatsapp_number}`} target="_blank" className="fieadWhat">
        
        {/* whawtapp icon from fontawsome  */}

        <i className="bi bi-whatsapp" style={{color:'white'}}></i>

    </a>

    <a className="scroll-to-top-btn visible" href="#" aria-label="scroll to top">
        <i className="bi bi-chevron-up"></i>
    </a>
    </>
  )
}