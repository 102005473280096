const GTMHelper = {
  /**
   * Pushes an event to the dataLayer
   * @param {string} eventName - The name of the event
   * @param {object} eventData - Additional event data
   */
  pushEvent: (eventName, eventData = {}) => {
    // Ensure dataLayer exists
    window.dataLayer = window.dataLayer || []

    // Completely reset the dataLayer array
    window.dataLayer.length = 0

    // Push new event data
    window.dataLayer.push({ event: eventName, ...eventData })

    //console.log("GTM Event Pushed:", eventName, eventData);
  },

  /**
   * Pushes a view_item event
   * @param {object} selectedProduct - The selected product details
   */
  pushViewItem: (selectedProduct) => {
    if (!selectedProduct) return

    const eventData = {
      event: "view_item",
      ecommerce: {
        currency: "BDT",
        value: Number.parseFloat(selectedProduct.price) || 0,
        items: [
          {
            item_id: selectedProduct.id.toString(),
            item_name: selectedProduct.product_name,
            price: Number.parseFloat(selectedProduct.price) || 0,
            item_brand: selectedProduct.brand?.name || "No Brand",
            item_category: selectedProduct.category?.name || "Uncategorized",
            quantity: 1,
          },
        ],
      },
    }

    GTMHelper.pushEvent("view_item", eventData)
  },

  pushAddToCart: (selectedProduct) => {
    if (!selectedProduct) return

    const eventData = {
      event: "add_to_cart",
      ecommerce: {
        currency: "BDT",
        value: Number.parseFloat(selectedProduct.price) || 0,
        items: [
          {
            item_id: selectedProduct.id.toString(),
            item_name: selectedProduct.product_name,
            price: Number.parseFloat(selectedProduct.price) || 0,
            item_brand: selectedProduct.brand?.name || "No Brand",
            item_category: selectedProduct.category?.name || "Uncategorized",
            quantity: 1,
          },
        ],
      },
    }

    GTMHelper.pushEvent("add_to_cart", eventData)
  },


  pushdiractBuy: (selectedProduct) => {
    console.log("Selected Product:", selectedProduct); // Debugging

    // Ensure selectedProduct is an array and has at least one item
    if (!Array.isArray(selectedProduct) || selectedProduct.length === 0) {
        console.error("Invalid product data:", selectedProduct);
        return;
    }

    // Access the first item in the array
    const productData = selectedProduct[0];

    if (!productData.product) {
        console.error("Product data is missing:", productData);
        return;
    }

    const eventData = {
        event: "add_to_cart",
        ecommerce: {
            currency: "BDT",
            value: Number.parseFloat(productData.product.price) || 0,
            items: [
                {
                    item_id: productData.product.id.toString(),
                    item_name: productData.product.product_name,
                    price: Number.parseFloat(productData.product.price) || 0,
                    item_brand: productData.product.brand?.name || "No Brand",
                    item_category: productData.product.category?.name || "Uncategorized",
                    quantity: productData.quantity || 1,
                    attributes: productData.attributes?.map(attr => ({
                        attribute_name: attr.attribute_name || "Unknown",
                        attribute_value: attr.attribute_option || "Unknown"
                    })) || []
                }
            ]
        }
    };

    console.log("Event Data:", eventData); // Debugging

    GTMHelper.pushEvent("add_to_cart", eventData);
},




  /**
   * Pushes a begin_checkout event
   * @param {array} itemList - The list of items being checked out
   * @param {number} totalValue - The total checkout value
   */
  pushBeginCheckout: (itemList, totalValue) => {
    if (!Array.isArray(itemList) || itemList.length === 0) return

    // Check if we've already fired this event in this session
    if (
      typeof window !== "undefined" &&
      window.sessionStorage &&
      window.sessionStorage.getItem("begin_checkout_fired")
    ) {
      console.log("Begin checkout event already fired in this session")
      return
    }

    const eventData = {
      event: "begin_checkout", // Removed "test" from event name
      ecommerce: {
        currency: "BDT",
        value: totalValue || GTMHelper.calculateTotalValue(itemList),
        items: itemList.map((item) => ({
          item_id: item.product.id,
          item_name: item.product.product_name,
          price: item.product.price,
          quantity: item.quantity,
          variant: GTMHelper.formatVariantsString(item.attributes),
        })),
      },
      custom_data: {
        selected_attributes: itemList.flatMap((item) => item.attributes || []),
        attribute_values: itemList.flatMap((item) => item.attribute_values || []),
        campaign_id: itemList[0]?.campaign_id || null,
        discount_value: itemList[0]?.discount_value || null,
        is_free_shipping: itemList.some((item) => item.product.free_delivary),
      },
    }

    GTMHelper.pushEvent("begin_checkout", eventData)

    // Mark that we've fired this event
    if (typeof window !== "undefined" && window.sessionStorage) {
      window.sessionStorage.setItem("begin_checkout_fired", "true")
    }
  },

  /**
   * Calculates the total value of items
   * @param {array} itemList - The list of items
   * @returns {number} - The total value
   */
  calculateTotalValue: (itemList) => {
    if (!Array.isArray(itemList) || itemList.length === 0) return 0

    return itemList.reduce((sum, item) => {
      const price = Number.parseFloat(item.product?.price || 0)
      const quantity = Number.parseInt(item.quantity || 1, 10)
      return sum + price * quantity
    }, 0)
  },

  /**
   * Pushes a purchase event
   * @param {object} orderData - The order details
   */
  pushPurchase: (orderData) => {
    if (!orderData || !orderData.items) {
      console.error("Invalid order data provided")
      return
    }

    const items = orderData.items.map((item) => {
      // Handle attributeOptionId as a comma-separated string
      const variant = item.attributeOptionId ? GTMHelper.formatVariantsString(item.attributeOptionId.split(",")) : ""

      return {
        item_id: item.product?.id || "",
        item_name: item.product?.product_name || "",
        price: String(item.individual_price || 0),
        quantity: String(item.quantity || 1),
        variant: variant, // Parsed variant from attributeOptionId
        product_code: item.product?.product_code || "",
        total: String(item.total || 0),
        campaign_discount: String(item.campaign_discount || 0),
        coupon_discount: String(item.coupon_discount || 0),
        original_price: String(item.original_price || 0),
      }
    })

    const purchaseData = {
      event: "purchase", // Removed "test" from event name
      ecommerce: {
        transaction_id: orderData.invoice_number || orderData.transaction_id || "",
        currency: "BDT",
        value: orderData.total_price || 0,
        shipping: orderData.shipping_price || 0,
        tax: orderData.tax || 0,
        items,
      },
      custom_data: {
        payment_method: orderData.payment_method || "",
        shipping_method: orderData.delivery || "",
        discount_applied: orderData.discount || 0,
        coupon_code: "", // Not present in your API, keeping as empty string
        delivery_charge: orderData.delivery_charge || 0,
        order_details: {
          paid_amount: orderData.paid_amount || 0,
          remaining_balance: (orderData.total_price || 0) - (orderData.paid_amount || 0), // Calculated
          order_type: orderData.order_type || "",
          order_status: orderData.order_status || "",
          delivery: orderData.delivery || "",
        },
        customer_details: {
          name: orderData.customer_name || "",
          address: orderData.address || "",
          note: orderData.note || "",
          user_identifier: orderData.user_identifier || "",
        },
      },
    }

    // Push to dataLayer
    GTMHelper.pushEvent("purchase", purchaseData)

    // Clear the begin_checkout flag after purchase is complete
    // This allows a new begin_checkout event if the user starts a new checkout
    if (typeof window !== "undefined" && window.sessionStorage) {
      window.sessionStorage.removeItem("begin_checkout_fired")
    }
  },

  /**
   * Hashes sensitive data (email, phone) for security before sending
   * @param {string} data - The data to hash
   * @returns {Promise<string>} - Hashed data
   */
  async hashData(data) {
    const encoder = new TextEncoder()
    const dataBuffer = encoder.encode(data)
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer)
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("")
  },

  /**
   * Formats item variants into a readable string
   * @param {array|string} attributes - The list of product attributes or comma-separated string
   * @returns {string} - Formatted string of variants
   */
  formatVariantsString(attributes) {
    if (!attributes) return ""

    // Handle if attributes is already a string
    if (typeof attributes === "string") return attributes

    // Handle array of attributes
    if (Array.isArray(attributes)) {
      return attributes
        .map((attr) => {
          // Handle different attribute formats
          if (typeof attr === "string") return attr

          // Handle object with name/value properties
          if (attr.name && attr.value) {
            return `${attr.name}: ${attr.value}`
          }

          // Handle object with attribute_name/attribute_option properties
          if (attr.attribute_name && attr.attribute_option) {
            return `${attr.attribute_name}: ${attr.attribute_option}`
          }

          return ""
        })
        .filter(Boolean) // Remove empty strings
        .join(", ")
    }

    return ""
  },
}

export default GTMHelper

