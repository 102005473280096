import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLoading } from "../LoadingContext"; // Adjust the import path
import { useToast } from "../ToastContext";
import { fetchHomeData } from "../../api/homeservice";
import { useDispatch, useSelector } from "react-redux";
export default function Bottom() {
  const [CategoryData, setCategoryData] = useState([]);
  const { setLoading } = useLoading(); // Access setLoading
  const { showToast } = useToast();
  const dispatch = useDispatch();
  // Get categories from Redux store
  const categories = useSelector((state) => state.home.categories.slice(0, 12));
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  // Fetch categories once when component mounts
  useEffect(() => {
    if (categories.length === 0) {
      // setCategoryData(categories)
      // dispatch(fetchHomeData());
    }
  }, [dispatch, categories.length]);

  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light navbar-default py-0 pb-lg-4 header-bottom"
        aria-label="Offcanvas navbar large"
      >
        <div className="container">
          <div
            className="offcanvas offcanvas-start"
            id="navbar-default"
            aria-labelledby="navbar-defaultLabel"
          >
            {/* <div className="offcanvas-header pb-1">
              <Link to="/">
                <img
                  src="assets/images/logo/1727679220.webp"
                  alt="eCommerce HTML Template"
                />
              </Link>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="offcanvas-body">
              {/* <div className="d-block d-lg-none mb-4">
                <form action="#">
                  <div className="input-group">
                    <input
                      className="form-control rounded"
                      type="search"
                      placeholder="Search for products"
                    />
                    <span className="input-group-append">
                      <button
                        className="btn bg-white border border-start-0 ms-n10 rounded-0 rounded-end"
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-search"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                    </span>
                  </div>
                </form>
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-outline-gray-400 text-muted w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#locationModal"
                  >
                    <i className="feather-icon icon-map-pin me-2"></i>
                    Pick Location
                  </button>
                </div>
              </div>
              <div className="d-block d-lg-none mb-4">
                <a
                  className="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-grid"
                    >
                      <rect x="3" y="3" width="7" height="7"></rect>
                      <rect x="14" y="3" width="7" height="7"></rect>
                      <rect x="14" y="14" width="7" height="7"></rect>
                      <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                  </span>
                  All Departments
                </a>
                <div className="collapse mt-2" id="collapseExample">
                  <div className="card card-body">
                    <ul className="mb-0 list-unstyled">
                      {categories.map((ctg) => (
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/filter/?category=" + ctg?.slug}
                          >
                            {" "}
                            {ctg?.name}{" "}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dropdown me-3 d-none d-lg-block">
                <button
                  className="btn btn-primary px-6"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-grid"
                    >
                      <rect x="3" y="3" width="7" height="7"></rect>
                      <rect x="14" y="3" width="7" height="7"></rect>
                      <rect x="14" y="14" width="7" height="7"></rect>
                      <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                  </span>
                  All Departments
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {categories.map((ctg) => (
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/filter/?category=" + ctg?.slug}
                      >
                        {" "}
                        {ctg?.name}{" "}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
        
                <ul className="navbar-nav align-items-center m-auto">
                  {categories.map((ctg) => (
                    <li className={"nav-item w-100 dropdown w-lg-auto"}>
                      <Link
                        className={
                          ctg?.sub_category?.length > 0
                            ? "nav-link dropdown-toggle"
                            : "nav-link"
                        }
                        to={"/filter/?category=" + ctg?.slug}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {ctg?.name}
                      </Link>
                      {ctg?.sub_category?.length > 0 ? (
                        <ul className="dropdown-menu">
                          {ctg?.sub_category?.map((ctg) => (
                            <li>
                              <Link
                                className="dropdown-item"
                                to={"/filter/?subcategory=" + ctg?.id}
                              >
                                {ctg.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}

                  
                </ul>
        
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
