import React from "react";

import HeaderTop from "./header/top";
import HeaderMiddle from "./header/middle";
import Headerbottom from "./header/bottom";
import Mobileheader from "./header/mobileheader";
export default function Header() {

  return (
    <>
      <div className="border-bottom header">
       <HeaderTop/>
       <HeaderMiddle/>
       <Headerbottom/>

     
   
      </div>

      
      <Mobileheader/>
    </>
  );
}
