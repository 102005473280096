import React, { useState, useEffect, useCallback ,useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLoading } from "../LoadingContext";
import { useToast } from "../ToastContext";
import Productmodel from "./productmodel";
import Detailstab from "./detailstab";
import RelatedProduct from "./related";
import { fetchHomeData } from "../../api/homeservice";
import {Helmet} from "react-helmet";
import GTMHelper from "../../utils/gtmHelper";
import { use } from "react";

const Product = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { showToast } = useToast();

  // Redux state
  const products = useSelector((state) => state.home.products);
  const siteinfos = useSelector((state) => state.home.siteinfos[0]);
  const error = useSelector((state) => state.home.error);
  const loading = useSelector((state) => state.home.loading);

  // Local state
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("product");

  // Fetch product data when component mounts
  const fetchProductData = useCallback(() => {
    if (!products || products.length === 0) {
      dispatch(fetchHomeData());
    }
  }, [dispatch, products]);

  useEffect(() => {
    document.title=siteinfos?.product_page_title || 'React app by softexel';
    setLoading(loading);
    fetchProductData();
  }, [fetchProductData, loading, setLoading]);

  useEffect(() => {
    if (products.length > 0) {
      const foundProduct = products.find((p) => p.slug === slug);
      const related = products.filter(
        (p) =>
          p.category_id === foundProduct?.category_id && // Same category
          p.slug !== slug // Exclude the current product
      );

      setSelectedProduct(foundProduct || null);
      setRelatedProducts(related);
    }
  }, [products, slug]);

  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);
 


  // useEffect(() => {
  //   if (selectedProduct?.id) {
  //     if (previousProductRef.current === selectedProduct.id) {
  //       return;
  //     }
    
  //     previousProductRef.current = selectedProduct.id;
    

      
  //     window.dataLayer = window.dataLayer ||  [];

  //     window.dataLayer.push({ ecommerce: null });
    

  //     window.dataLayer.push({
  //       event: "view_item",
  //       ecommerce: {
  //         currency: "BDT",
  //         value: parseFloat(selectedProduct.price) || 0,
  //         items: [
  //           {
  //             item_id: selectedProduct.id.toString(),
  //             item_name: selectedProduct.product_name,
  //             price: parseFloat(selectedProduct.price) || 0,
  //             item_brand: selectedProduct.brand?.name || "No Brand",
  //             item_category: selectedProduct.category?.name || "Uncategorized",
  //             quantity: 1,
  //           },
  //         ],
  //       },
  //     });
      
  //     console.log("GTM Event Pushed:", "view_item", window.dataLayer);
  //   }
  // }, [selectedProduct]);

  // const previousProductRef = useRef(null);


  useEffect(() => {
    if (selectedProduct?.id) {
      GTMHelper.pushViewItem(selectedProduct);
    }
  }, [selectedProduct]);
  
  

  // Tabs
  const tabs = [
    { id: "product", label: "Product Details" },
    // { id: "details", label: "Information" },
    // { id: "reviews", label: "Reviews" },
  ];

  const generateProductSchema = () => {
    if (!selectedProduct) return null;
  
    const safePrice = selectedProduct.price 
      ? parseFloat(selectedProduct.price).toFixed(2) 
      : "0.00"; // Default to "0.00" if price is null/undefined
  
    return {
      "@context": "https://schema.org",
      "@type": "Product",
      "productID": selectedProduct.id?.toString() || "N/A", // Ensure it's a string
      "name": selectedProduct.product_name?.slice(0, 500) || "Unnamed Product", // Max 500 chars
      "description": selectedProduct.description?.slice(0, 500) || "No description available.", // Max 500 chars
      "url": window.location.href,
      "image": selectedProduct.featured_image || "",
  
      "brand": {
        "@type": "Brand",
        "name": selectedProduct.brand?.name?.slice(0, 500) || "No Brand", // Max 500 chars
      },
  
      "offers": [
        {
          "@type": "Offer",
          "price": safePrice, // Ensure valid price or fallback to "0.00"
          "priceCurrency": "BDT",
          "availability": selectedProduct.quantity > 0 
            ? "https://schema.org/InStock" 
            : "https://schema.org/OutOfStock",
          "itemCondition": selectedProduct.condition === "used"
            ? "https://schema.org/UsedCondition"
            : selectedProduct.condition === "refurbished"
            ? "https://schema.org/RefurbishedCondition"
            : "https://schema.org/NewCondition", // Default to new
        }
      ]
    };
  };
  

  return (
    <>
   
      {selectedProduct ? (
        <>
        
        <script
  type="application/ld+json"
  dangerouslySetInnerHTML={{
    __html: JSON.stringify(generateProductSchema(), null, 2),
  }}
/>


          {/* Product Section */}
          <section className="mt-8 single-product-viewpage">
            <div className="container">
              <div className="row">
                <Productmodel selectedProduct={selectedProduct} siteinfos={siteinfos} />
              </div>
            </div>
          </section>

          {/* Tabs Section */}
          <section className="mt-lg-14 mt-8">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
             
                  <ul className="nav nav-pills nav-lb-tab" role="tablist">
                    {tabs.map((tab) => (
                      <li key={tab.id} className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                          onClick={() => setActiveTab(tab.id)}
                          role="tab"
                          aria-selected={activeTab === tab.id}
                          aria-controls={`${tab.id}-tab-pane`}
                        >
                          {tab.label}
                        </button>
                      </li>
                    ))}
                  </ul>

             
                  <div className="tab-content" id="myTabContent">
                
                    <div
                      className={`tab-pane fade ${
                        activeTab === "product" ? "show active" : ""
                      }`}
                      role="tabpanel"
                      aria-labelledby="product-tab"
                    >
                      <div className="my-8 bg-white p-5 rounded-2">
                        {/* <div className="mb-5">
                          {selectedProduct?.short_description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedProduct.short_description,
                              }}
                            />
                          )}
                        </div> */}
                        <div className="mb-5">
                          {selectedProduct?.description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: selectedProduct.description,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                 
                    <div
                      className={`tab-pane fade ${
                        activeTab === "details" ? "show active" : ""
                      }`}
                      role="tabpanel"
                      aria-labelledby="details-tab"
                    >
                      <Detailstab specifications={selectedProduct?.specification} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Related Products Section */}
          <RelatedProduct ProductData={relatedProducts} />
        </>
      ) : (
        <div className="container mt-8">
          <div className="row">
            <div className="col-12 text-center">
              <h2>No product found</h2>
              <p>It seems like the product you're looking for is not available.</p>
              <Link to="/" className="btn btn-primary">
                Browse More Products
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
