import React from 'react'
import Main from '../components/contact/main'
import Header from '../components/Header'
import Footer from '../components/Footer'
export default function Contact() {
  return (
    <>
    <Header/>
    <Main/>
    <Footer/>
    </>
  )
}
