import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AccountComponent from '../components/auth/account/payment'

export default function Accountpayment() {
  return (
   <>
   <Header/>
    <AccountComponent/>
    <Footer/>
   </>
  )
}
