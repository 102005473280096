import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Check } from "lucide-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import InvoiceComponent from "./orderinvoice";
import GTMHelper from "../utils/gtmHelper";

const CheckoutSuccess = () => {
  const currentOrder = useSelector((state) => state.order.currentOrder);
  const currentOrderpayload = useSelector(
    (state) => state.order.currentOrderpayload
  );
  const sitedata = useSelector((state) => state.order.sitedata);
  const invoiceRef = useRef();

  const formatPrice = (price) => `৳ ${Number(price).toLocaleString("bn-BD")}`;

  useEffect(() => {
    console.log("useEffect triggered, currentOrderpayload:", currentOrderpayload);
    if (!currentOrderpayload) {
      console.log("No order payload available");
      return;
    }
    if (!currentOrderpayload.items) {
      console.log("No items in order payload");
      return;
    }

    try {
      console.log("Calling GTMHelper.pushPurchase with:", currentOrderpayload);
      GTMHelper.pushPurchase(currentOrderpayload);
    } catch (error) {
      console.error("Error tracking purchase:", error.message, error.stack);
    }
  }, [currentOrderpayload]);

  const handleDownloadInvoice = () => {
    if (invoiceRef.current) {
      invoiceRef.current.downloadInvoice();
    }
  };

  if (!currentOrderpayload) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <div className="col-md-5 m-auto">
        <div className="d-flex flex-column align-items-center">
          <div className="bg-light w-100 max-w-50 mt-5 p-4 text-center">
            <div className="d-flex justify-content-center mb-4">
              <div
                className="bg-success rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: "50px", height: "50px" }}
              >
                <Check className="text-white" />
              </div>
            </div>

            <h1 className="h4 text-dark">Thank you for your purchase</h1>
            <p className="text-muted mt-2">
              We've received your order and will ship it in 5-7 business days.
            </p>
            <p className="text-muted mt-1">
              Your order number is #{currentOrderpayload?.invoice_number || ""}.
            </p>

            <div className="bg-white p-4 rounded mt-4">
              <h2 className="h5 text-dark text-start">Order Summary</h2>
              <table className="table mt-3">
                <tbody>
                  {currentOrderpayload?.items?.map((item, index) => (
                    <tr key={index} className="border-bottom">
                      <td className="align-middle">
                        {item?.product?.featured_image && (
                          <img
                            src={item.product.featured_image}
                            alt={item.product?.product_name || "Product"}
                            className="rounded"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </td>
                      <td className="align-middle">
                        {item.product?.product_name || "N/A"}
                      </td>
                      <td className="align-middle text-end">
                        <span className="success_price">
                          {formatPrice(item.total || 0)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2" className="text-end text-muted">
                      Delivery charge
                    </td>
                    <td className="text-end fw-bold">
                      <span className="success_price">
                        {formatPrice(currentOrderpayload?.delivery_charge || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-end text-muted">
                      Total
                    </td>
                    <td className="text-end fw-bold">
                      <span className="success_price">
                        {formatPrice(currentOrderpayload?.total_price || 0)}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="mt-4">
              <button
                className="btn btn-outline-secondary"
                onClick={handleDownloadInvoice}
              >
                Download invoice
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <InvoiceComponent
          ref={invoiceRef}
          currentOrder={currentOrderpayload}
          currentOrderpayload={currentOrder}
          sitedata={sitedata}
          formatPrice={formatPrice}
        />
      </div>
      <Footer />
    </>
  );
};

export default CheckoutSuccess;