import React, { useEffect } from "react";
import ReactSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData } from "../../api/homeservice";
import { useLoading } from "../LoadingContext";
import { useToast } from "../ToastContext";
import ImageLoader from "../ImageLoader";

export default function Slider() {
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { showToast } = useToast();

  const sliders = useSelector((state) => state.home.sliders);
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  useEffect(() => {
    if (sliders.length === 0) {
      dispatch(fetchHomeData());
    }
  }, [dispatch, sliders.length]);

  useEffect(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error, showToast]);

  const settings = {
    dots: true,
    infinite: sliders.length > 1, // Disable infinite loop if only one image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: sliders.length > 1, // Only autoplay if more than one slide
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <section>
      <div className="hero-slider">
        {loading ? (
          <div>Loading...</div>
        ) : sliders.length === 0 ? (
          <div className="no-slider">No images available</div> // Fallback UI
        ) : (
          <ReactSlider {...settings}>
            {sliders.map((slide) => (
              <div key={slide.id} className="slider">
                <ImageLoader
                  src={slide.image_path}
                  alt={`slider-${slide.id}`}
                  className="slider-image"
                />
              </div>
            ))}
          </ReactSlider>
        )}
      </div>
    </section>
  );
}
