import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { LoadingProvider } from './components/LoadingContext'; 
import GlobalLoader from './components/GlobalLoader'; 
import Home from './pages/home';
import Page404 from './pages/page404';
import SignupPage from './pages/signuppage';
import SigninPage from './pages/signinpage';
import Wishlist from './pages/wishlist';
import Productpage from './pages/product';
import Cart from './pages/cart';
import ForgetPassword from './pages/forgetpassword';
import Checkout from './pages/checkout';
import CheckoutSucess from './pages/checkoutsuccess';
import PaymentSucess from './pages/paymentsuccess';
import PaymentError from './pages/paymenterror';
import Filter from './pages/filter';
import Account from './pages/account';
import TrackOrder from './pages/track';
import AccountSetting from './pages/accountsetting';
import AccountAddress from './pages/accountaddress';
import AccountPayment from './pages/accountpayment';
import AccountNotification from './pages/accountnotification';
import About from './pages/about';
import Contact from './pages/contact';
import { ToastProvider } from './components/ToastContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { GuestRoute } from './components/GuestRoute';
import DynamicPage from './pages/dynamicpage';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomeData } from './api/homeservice';
import { useLoading } from './components/LoadingContext';
import ScrollToTop from './components/ScrollToTop';


function AppRoute() {


  const siteinfos = useSelector((state) => state.home?.siteinfos[0]);
  const media = useSelector((state) => state.home?.siteinfos?.media || []);
  const marketing = useSelector((state) => state.home?.siteinfos?.marketing || []);
  const favicon = media[0]?.favicon;

  useEffect(() => {
    // Set the page title
    document.title = siteinfos?.app_name || '';

    // Check if a favicon exists
    if (favicon) {
      // Remove any existing favicon link
      const existingFavicon = document.querySelector("link[rel='icon']");
      if (existingFavicon) {
        existingFavicon.remove();
      }

      // Create a new link tag for favicon
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = favicon;
      document.head.appendChild(link);
    }
  //     // Add marketing scripts
  // marketing.forEach((item) => {
  //   if (item?.script) {
  //     // Check if script already exists
  //     if (!document.querySelector(`script[data-marketing="${item.identifier}"]`)) {
  //       const tempDiv = document.createElement('div');
  //       tempDiv.innerHTML = item.script.trim(); // Parse the script safely

  //       const scriptElement = tempDiv.querySelector('script');
  //       if (scriptElement) {
  //         scriptElement.setAttribute('data-marketing', item.identifier); // Add identifier
  //         document.head.appendChild(scriptElement);
  //       } else {
  //         console.error(`Invalid script format in marketing item: ${item.identifier}`);
  //       }
  //     }
  //   }
  // });
  }, [favicon, siteinfos]); // Re-run effect when favicon or siteinfos change

  return (
    <LoadingProvider>
      <ToastProvider>
        <GlobalLoader />
        <Router>
          <ScrollToTop />
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/product/:slug" element={<Productpage />} />
            <Route path="/filter" element={<Filter />} />
            <Route path="/payment/success" element={<PaymentSucess />} />
            <Route path="/payment/error" element={<PaymentError />} />
            
            {/* Guest Routes (Only accessible when not logged in) */}
            <Route
              path="/signup"
              element={
                <GuestRoute>
                  <SignupPage />
                </GuestRoute>
              }
            />
            <Route
              path="/signin"
              element={
                <GuestRoute>
                  <SigninPage />
                </GuestRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <GuestRoute>
                  <ForgetPassword />
                </GuestRoute>
              }
            />
            
            {/* Protected Routes (Only accessible when logged in) */}
            <Route
              path="/cart"
              element={
                <ProtectedRoute>
                  <Cart />
                </ProtectedRoute>
              }
            />
            <Route
              path="/checkout"
              element={
              
                  <Checkout />
             
              }
            />
            <Route
              path="/checkout/success/:invoice"
              element={
              
                  <CheckoutSucess />
             
              }
            />
            <Route
              path="/:pageType"
              element={
              
                  <DynamicPage />
             
              }
            />
            <Route
              path="/order/tracking"
              element={
              
                  <TrackOrder />
             
              }
            />
            <Route
              path="/shop-wishlist"
              element={
                <ProtectedRoute>
                  <Wishlist />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account-orders"
              element={
                <ProtectedRoute>
                  <Account />
                  </ProtectedRoute>
              }
            />
            <Route
              path="/account-settings"
              element={
                <ProtectedRoute>
                  <AccountSetting />
                  </ProtectedRoute>
               
              }
            />
            <Route
              path="/account-address"
              element={
               
                <ProtectedRoute>
                  <AccountAddress />
                  </ProtectedRoute>
              }
            />
            <Route
              path="/account-payment-method"
              element={
                <ProtectedRoute>
                  <AccountPayment />
                  </ProtectedRoute>
              }/>
            <Route
              path="/account-notification"
              element={
                <ProtectedRoute>
                  <AccountNotification />
                  </ProtectedRoute>
              }
            />
            
            {/* Dashboard redirect */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <AccountSetting />
                  </ProtectedRoute>
              }
            />
            
            {/* 404 Route */}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Router>
      </ToastProvider>
    </LoadingProvider>
  );
}

export default AppRoute;