import ReactGTM from 'react-gtm-module';
import FacebookPixel from 'react-facebook-pixel';

class EcommerceDataLayerService {
  constructor() {
    this.initialized = false;
  }

  init({ gtmId, fbPixelId }) {
    if (this.initialized) return;
  
    // Initialize dataLayer properly
    window.dataLayer = window.dataLayer || [];
    
    if (gtmId) {
      // Initialize GTM with proper configuration
      ReactGTM.initialize({
        gtmId,
        dataLayerName: 'dataLayer',
        events: {
          // Optional: Send a pageview event on initialization
          pageView: true
        }
      });
      
      console.log("GTM initialized with ID:", gtmId);
    }
  
    // Facebook Pixel initialization remains the same
    if (fbPixelId) {
      FacebookPixel.init(fbPixelId, {
        autoConfig: true,
      });
    }
  
    this.initialized = true;
  }

  // Product Page View
  trackProductView(product, selectedAttributes = {}) {
    // const productData = {
    //   event: 'view_item',
    //   ecommerce: {
    //     currency: 'BDT',
    //     value: product.price,
    //     items: [{
    //       item_id: product.id,
    //       item_name: product.product_name,
    //       price: product.price,
    //       variant: this.formatVariantsString(selectedAttributes),
    //       category: product.category_name,
    //       brand: product.brand_name,
    //       quantity: 1
    //     }]
    //   },
    //   custom_data: {
    //     is_free_shipping: product.is_free_shipping,
    //     campaign_id: product.product_campaign?.campaign_id,
    //     discount: product.product_campaign?.campaign?.discount
    //   }
    // };

    // window.dataLayer?.push(productData);

    FacebookPixel.track('ViewContent', {
      content_ids: [product.id],
      content_name: product.product_name,
      content_type: 'product',
      value: product.price,
      currency: 'BDT'
    });
  }


  trackAddToCart = (cartState) => {
    console.log("Tracking Add to Cart Event", cartState);
  
    try {
      const { items, total, itemCount } = cartState;
  
      // Validate cart state
      if (!items || items.length === 0 || parseFloat(total) === 0 || itemCount === 0) {
        console.warn("Invalid cart state for tracking.");
        return;
      }
  
      // Prepare items for tracking
      const trackingItems = items.map((item) => ({
        item_id: item.product_id || "No product id",
        item_name: item.product?.product_name || "No product name",
        price: item.product?.price ? parseFloat(item.product.price) : 0,
        variant: item.attributeKey || "",
        quantity: item.quantity || 0,
      }));
  
      // Construct tracking payload
      const cartData = {
        event: "add_to_cart",
        ecommerce: {
          currency: "BDT",
          value: parseFloat(total), // Ensure value is a number
          items: trackingItems,
          item_count: itemCount,
        },
      };
  
      // Push to DataLayer (Google Tag Manager)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(cartData);
  
      console.log("Cart tracking successful:", cartData);
    } catch (error) {
      console.error("Error tracking cart:", error);
    }
  };
  
  
  

  // Direct Checkout
// Direct Checkout
// trackDirectCheckout(items) {
//     // Ensure items has the required structure
//     const normalizedItems = {
//       id: items.id || null, // Will be assigned by cart system
//       product_id: items.product_id || items.product?.id, // Fallback to nested product.id
//       quantity: items.quantity || 1, // Default quantity
//       campaign_id: items.campaign_id || null,
//       discount_value: items.discount_value || null,
//       product: {
//         id: items.product?.id || items.product_id, // Fallback to product_id
//         product_name: items.product?.product_name || 'Unknown Product',
//         free_delivary: items.product?.free_delivary || false,
//         price: items.product?.price || 0, // Default price
//         featured_image: items.product?.featured_image || '',
//       },
//       attribute_values: items.attribute_values || [],
//       attributes: items.attributes || [],
//     };
  
//     // Calculate the total value of the items
//     const totalValue = normalizedItems.product.price * normalizedItems.quantity;
  
//     // Prepare the checkout data for GTM
//     const checkoutData = {
//       event: 'begin_checkout',
//       ecommerce: {
//         currency: 'BDT',
//         value: totalValue,
//         items: [{
//           item_id: normalizedItems.product.id,
//           item_name: normalizedItems.product.product_name,
//           price: normalizedItems.product.price,
//           quantity: normalizedItems.quantity,
//           variant: this.formatVariantsString(normalizedItems.attributes) // Assuming this function exists
//         }]
//       },
//       custom_data: {
//         selected_attributes: normalizedItems.attributes,
//         attribute_values: normalizedItems.attribute_values,
//         campaign_id: normalizedItems.campaign_id,
//         discount_value: normalizedItems.discount_value,
//         is_free_shipping: normalizedItems.product.free_delivary
//       }
//     };
  
//     // Clear previous ecommerce data and push new checkout data to GTM
//     window.dataLayer?.push({ ecommerce: null });
//     window.dataLayer?.push(checkoutData);
  
//     // Track the event using Facebook Pixel
//     FacebookPixel.track('InitiateCheckout', {
//       content_ids: [normalizedItems.product.id],
//       content_name: normalizedItems.product.product_name,
//       value: totalValue,
//       currency: 'BDT',
//       contents: [{
//         id: normalizedItems.product.id,
//         quantity: normalizedItems.quantity
//       }]
//     });
//   }



// Direct Checkout for Multiple Items
trackDirectCheckout(items) {
    // Ensure items is an array (even for single items)
    const itemList = Array.isArray(items) ? items : [items];
  
    // Calculate the total value of all items
    const totalValue = itemList.reduce((total, item) => {
      return total + (item.product.price * item.quantity);
    }, 0);
  
    // Prepare the checkout data for GTM
    const checkoutData = {
      event: 'begin_checkout',
      ecommerce: {
        currency: 'BDT',
        value: totalValue,
        items: itemList.map((item) => ({
          item_id: item.product.id,
          item_name: item.product.product_name,
          price: item.product.price,
          quantity: item.quantity,
          variant: this.formatVariantsString(item.attributes) // Assuming this function exists
        }))
      },
      custom_data: {
        selected_attributes: itemList.flatMap((item) => item.attributes || []),
        attribute_values: itemList.flatMap((item) => item.attribute_values || []),
        campaign_id: itemList[0]?.campaign_id || null, // Use the first item's campaign_id
        discount_value: itemList[0]?.discount_value || null, // Use the first item's discount_value
        is_free_shipping: itemList.some((item) => item.product.free_delivary) // Check if any item has free shipping
      }
    };
  
    // Clear previous ecommerce data and push new checkout data to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push(checkoutData);
  
    // Track the event using Facebook Pixel
    // FacebookPixel.track('InitiateCheckout', {
    //   content_ids: itemList.map((item) => item.product.id),
    //   content_name: itemList.map((item) => item.product.product_name).join(', '),
    //   value: totalValue,
    //   currency: 'BDT',
    //   contents: itemList.map((item) => ({
    //     id: item.product.id,
    //     quantity: item.quantity
    //   }))
    // });
  }

  // Cart Page View
  trackCartView(cartItems, cartTotal) {
    const cartData = {
      event: 'view_cart',
      ecommerce: {
        currency: 'BDT',
        value: cartTotal,
        items: cartItems.map(item => ({
          item_id: item.product.id,
          item_name: item.product.product_name,
          price: item.product.price,
          quantity: item.quantity,
          variant: this.formatVariantsString(item.attributes)
        }))
      },
      custom_data: {
        items_count: cartItems.length,
        total_quantity: cartItems.reduce((sum, item) => sum + item.quantity, 0)
      }
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push(cartData);

    FacebookPixel.track('ViewCart', {
      content_type: 'product',
      contents: cartItems.map(item => ({
        id: item.product.id,
        quantity: item.quantity
      })),
      value: cartTotal,
      currency: 'BDT'
    });
  }

  // Checkout Steps
  trackCheckoutStep(step, cartItems, cartTotal, additionalData = {}) {
    const checkoutData = {
      event: 'checkout_progress',
      ecommerce: {
        currency: 'BDT',
        value: cartTotal,
        items: cartItems.map(item => ({
          item_id: item.product.id,
          item_name: item.product.product_name,
          price: item.product.price,
          quantity: item.quantity,
          variant: this.formatVariantsString(item.attributes)
        }))
      },
      checkout_step: step,
      custom_data: {
        ...additionalData
      }
    };

    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push(checkoutData);
  }

  async hashData(data) {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data.trim().toLowerCase()); // Normalize input
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
    return Array.from(new Uint8Array(hashBuffer))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  }
  
  

  // Purchase Success
  async trackPurchaseSuccess(orderData) {
    if (!orderData || !orderData.items) {
      console.error("Invalid order data provided");
      return;
    }
  
    // Hash sensitive user data before sending
    const hashedEmail = orderData.customer_details?.email 
      ? await this.hashData(orderData.customer_details.email) 
      : "";
  
    const hashedPhone = orderData.customer_details?.phone 
      ? await this.hashData(orderData.customer_details.phone) 
      : "";
  
    // Hash item attributes asynchronously
    const items = await Promise.all(orderData.items.map(async (item) => ({
      item_id: item.item_id || "",
      item_name: item.item_name || "",
      price: String(item.price || 0),
      quantity: String(item.quantity || 1),
      variant: this.formatVariantsString(item.attributes),
      product_code: item.product_code || "",
      total: String(item.total || 0),
      campaign_discount: String(item.campaign_discount || 0),
      coupon_discount: String(item.coupon_discount || 0),
      original_price: String(item.original_price || 0),
    })));
  
    const purchaseData = {
      event: "purchase",
      ecommerce: {
        transaction_id: orderData.order_id || orderData.transaction_id || "",
        currency: "BDT",
        value: orderData.total_amount || 0,
        shipping: orderData.shipping_cost || 0,
        tax: orderData.tax_amount || 0,
        items, // Use the resolved items array
      },
      custom_data: {
        payment_method: orderData.payment_method || "",
        shipping_method: orderData.shipping_method || "",
        discount_applied: orderData.discount_amount || 0,
        coupon_code: orderData.coupon_code || "",
        delivery_charge: orderData.delivery_charge || 0,
        order_details: {
          paid_amount: orderData.order_details?.paid_amount || 0,
          remaining_balance: orderData.order_details?.remaining_balance || 0,
          order_type: orderData.order_details?.order_type || "",
          order_status: orderData.order_details?.order_status || "",
          delivery: orderData.order_details?.delivery || "",
        },
        customer_details: {
          hashed_email: hashedEmail, // Hashed email
          hashed_phone: hashedPhone, // Hashed phone
          name: orderData.customer_details?.name || "",
          address: orderData.customer_details?.address || "",
          note: orderData.customer_details?.note || "",
          user_identifier: orderData.customer_details?.user_identifier || "",
        },
      },
    };
  
    // Push to dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer?.push({ ecommerce: null }); // Clear previous ecommerce data
    window.dataLayer?.push(purchaseData);
  
    // Track purchase with Facebook Pixel
    if (window.FacebookPixel) {
      FacebookPixel.track("Purchase", {
        content_type: "product",
        contents: orderData.items.map((item) => ({
          id: item.item_id || "",
          quantity: item.quantity || 1,
        })),
        value: orderData.total_amount || 0,
        currency: "BDT",
        transaction_id: orderData.order_id || orderData.transaction_id || "",
        user_data: {
          em: hashedEmail, // Hashed email
          ph: hashedPhone, // Hashed phone
        },
      });
    } else {
      console.warn("Facebook Pixel is not defined.");
    }
  
    console.log("DataLayer Event Sent:", window.dataLayer[window.dataLayer.length - 1]);
  }
  
  

  // Helper function to format variants
  formatVariantsString(attributes) {
    if (!attributes || !Array.isArray(attributes)) return '';
    return attributes
      .map(attr => `${attr.attribute_name}: ${attr.attribute_option}`)
      .join(', ');
  }
}

export const dataLayer = new EcommerceDataLayerService();